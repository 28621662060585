import React, { PureComponent } from 'react'

import { Image } from 'react-bootstrap'
import backgroundImage from '../Media/Home-Background.jpg'

export default class Home extends PureComponent {
    render() {
        return (
            <Image src={backgroundImage} style={{width: "100%"}}></Image>
        )
    }
}
