import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import Axios from 'axios'
//import './PrivacyPolicy.css'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

interface Props {

}


interface State {
    privacyPolicyHtml: string
    cookiePolicyHtml: string
    activeTab: number
}


export class PrivacyPolicy extends Component<Props, State> {


    state: Readonly<State> = {
        privacyPolicyHtml: "",
        cookiePolicyHtml: "",
        activeTab: 1
    }



    componentDidMount() {
        this.loadPrivacy()
        this.loadCookies()
    }

    loadPrivacy = async () => {
        const result = await Axios.get("https://www.iubenda.com/api/privacy-policy/40745462/no-markup")
        if (result.status === 200) {
            this.setState({
                privacyPolicyHtml: result.data.content
            })
        }
    }



    loadCookies = async () => {
        const result = await Axios.get("https://www.iubenda.com/api/privacy-policy/40745462/cookie-policy/no-markup")
        if (result.status === 200) {
            this.setState({
                cookiePolicyHtml: result.data.content
            })
        }
    }




    renderPrivacy = () => {
        if (this.state.privacyPolicyHtml) {
            return (
                <div>
                    {this.state.privacyPolicyHtml}
                </div>
            )
        }
    }



   handleSelect = (eventKey: any) => {
    this.setState({
        activeTab: eventKey
    })
   }


    render() {

        return (
            <div>
                <Container>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Datenschutz</title>
                    <meta name="description" content="Datenschutzerklärung und Cookie Policy von winemanager.app"/>
                    <meta name="robots" content="index, follow"/>
                    <link rel="canonical" href="https://weinmanager.app/privacyPolicy" />
                </Helmet>

                <Tabs className="privacyTab" defaultActiveKey={1} onSelect={this.handleSelect} id="controlledTab">
                    <Tab eventKey={1} title="Datenschutzerklärung">
                        <div style={{ paddingTop: "40px" }}>
                            <td dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyHtml }} />
                        </div>
                    </Tab>
                    <Tab eventKey={2} title="Cookie Policy">
                        <div style={{ paddingTop: "40px" }}>
                            <td dangerouslySetInnerHTML={{ __html: this.state.cookiePolicyHtml }} />
                        </div>
                    </Tab>
                </Tabs>
                </Container>
            </div>
        )
    }
}

export default PrivacyPolicy
