import React, { Component } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import { NavLink } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'

export class Navigationbar extends Component {
    render() {
        return (
            <div>
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand>
                        <NavLink className="whiteNavLink" to="/">Home</NavLink>
                    </Navbar.Brand> 
                    

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        
                        <Nav className="mr-auto">
                        </Nav>
                    
                        <Nav>
                            <Nav.Link> 
                                <NavLink to="/privacy" className="grayNavLink" activeClassName="grayNavLinkActive">Datenschutz</NavLink>
                            </Nav.Link>
                            <Nav.Link>
                                <NavLink to="/imprint" className="grayNavLink" activeClassName="grayNavLinkActive">Impressum</NavLink>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default Navigationbar
