import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { Jumbotron, Button, Navbar, Nav, Form, FormControl } from 'react-bootstrap'
import NavigationBar from './Components/Navigationbar'

import Home from './Components/Home'
import Imprint from './Components/Imprint'
import PrivacyPolicy from './Components/PrivacyPolicy'

function App() {
  return (
    <Router>
    <div className="App">
      <header>
      </header>

      <body>
        <Jumbotron fluid style={{marginBottom: "0px"}}>
          <Container>
          <h1>Teck Apps</h1>
            <h4>
              iOS & web development
            </h4>
          <p>
            <Button href="mailto:contact@teck-apps.com" variant="primary" style={{marginTop: "35px"}}>Kontakt</Button>
          </p>
          </Container>
      </Jumbotron>

    <NavigationBar></NavigationBar>


      <div>
          {renderCurrentPage()}
      </div>

      </body>
    </div>
    </Router>
  );
}


function renderCurrentPage() {
  return (
    <Switch>
        <Route path="/" exact>
          <Home></Home>
        </Route>

        <Route path="/imprint" exact >
          <Imprint></Imprint>
        </Route>

        <Route path="/privacy" exact >
          <PrivacyPolicy></PrivacyPolicy>
        </Route>

    </Switch>
  );
}



export default App;
